<template>
  <div class="backtest-detail">
    <div style="color:red;" v-if="debug">
        BacktestDetail
    </div>
    
    <span class="d-flex justify-start mb-6 text-h6">  
      <v-icon left @click="$router.go(-1)"> mdi-arrow-left </v-icon> 
      백 테스트 상세 결과 
    </span>
    <v-card flat><v-card-text>
    <v-container fluid>
      <v-row justify="start" align="center">
        <v-col cols="6" sm="4" md="4" lg="4" class="d-flex justify-start">
          <!-- 년/월 제목 -->
          <div>
            <v-btn icon color="indigo" @click="get_backtest_detail_prv()"> <v-icon>mdi-step-backward</v-icon> </v-btn>
              {{ yearMonth }}
            <v-btn icon color="indigo" @click="get_backtest_detail_next()"> <v-icon>mdi-step-forward</v-icon> </v-btn>
            {{ isRebalance }}
          </div>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" class="d-flex justify-start">
          <v-switch
            v-model="dateOrder"
            label="최신순"
            @change="changeOrder"
          ></v-switch>
        </v-col>        
        <v-col cols="6" sm="4" md="4" lg="4" class="d-flex justify-start">
          평균 수익율(%):
          <v-chip :color="getNumColor(backTestOverview['yield'])" dark >
              {{ backTestOverview['yield'] }}
          </v-chip>
        </v-col>      
      </v-row>
    </v-container>
    </v-card-text></v-card>
    <br>


    <!-- 테이블 -->
    <v-data-table
        :headers="header"
        :items="backTestList"
        :items-per-page="20"
        class="elevation-1"
        :hide-default-footer=true   
    >
    <template v-slot:item.trade_type="{ item }">
      <v-chip
        :color="getColor(item.trade_type)"
        dark
        outlined
      >
        {{ item.trade_type }}
      </v-chip>
    </template> 
    </v-data-table>
    
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: "BacktestDetail",
  data() {
      return {
        header: [],
        header0: [
            { text: '순위', align: 'start', value: 'rank' },
            { text: '종목명', value: 'company_name' },
            { text: '매수가', value: 'price', align: 'end' },
            { text: '수량', value: 'bqty', align: 'end' },
            { text: '매수금액', value: 'bamount', align: 'end' },
            { text: '거래유형', value: 'trade_type', align: 'end' },
        ],
        header1: [
            { text: '순위', align: 'start', value: 'rank' },
            { text: '종목명', value: 'company_name' },
            { text: '종가', value: 'price', align: 'end' },
            { text: '수량', value: 'bqty', align: 'end' },
            { text: '평가금액', value: 'bamount', align: 'end' },
            { text: '수익율', value: 'yield', align: 'end' },
            { text: '누적수익율', value: 'cum_yield', align: 'end' },
            { text: '거래유형', value: 'trade_type', align: 'end' },
        ],
        header2: [
            { text: '순위', align: 'start', value: 'rank' },
            { text: '종목명', value: 'company_name' },
            { text: '매수/매도가', value: 'price', align: 'end' },
            { text: '리밸런싱 전 수량', value: 'bqty', align: 'end' },
            { text: '리밸런싱 전 평가금액', value: 'bamount', align: 'end' },
            { text: '리밸런싱 후 수량', value: 'aqty', align: 'end' },
            { text: '리밸런싱 후 평가금액', value: 'aamount', align: 'end' },
            { text: '수익율', value: 'yield', align: 'end' },
            { text: '누적수익율', value: 'cum_yield', align: 'end' },
            { text: '거래유형', value: 'trade_type', align: 'end' },
        ],
        backtestDetail: {},
        yearMonth: '',
        yearMonthList: [],        
        backTestOverview: {},
        backTestList: [],
        selectedYearMonth: '',
        loadTable: true,
        next: 0,
        isRebalance: '',
        totalAmount: 0,
        v_yield: 0.0,
        isFirst: true,
        dateOrder: false,
      }
},
    
  computed: {
    ...mapState(['isLogin','debug', 'user', 'test_term', 'route', 'quant_id']) 
  },

  created () {     
    if (this.isLogin) this.get_backtest_detail(this.test_term[this.next]); 
    else this.$router.push('/');                     
  },
  
  methods: {
    get_backtest_detail(yyyymm) {
        
      var vm = this;    
      this.yearMonth = yyyymm;

      const req_data = {'user': this.user, 'yyyymm': yyyymm, 'route': this.route, 'quant_id': this.quant_id};
      const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};  
      axios.post('/api/quant/backtest_result/', req_data, {headers})
      .then(function(res) {
          vm.backtestDetail = res.data;

          // vm.yearMonthList = Object.keys(vm.backtestDetail)
          // vm.yearMonth = vm.yearMonthList[vm.next];
          // vm.backTestList = vm.backtestDetail[vm.yearMonth];
          vm.backTestOverview = vm.backtestDetail['overview'];
          vm.backTestList = vm.backtestDetail['list'];
          var isData = false;
          vm.backTestList.forEach(element => {
            if (element['aamount'] != null) {
              isData = true;
            }
          });
          
          if (isData) {
            vm.isRebalance = '리밸런싱 월';
            vm.header = vm.header2;
          } else {
            vm.isRebalance = ''
            vm.header = vm.header1;
          }
          
          //if (vm.isFirst) {
          //  vm.isRebalance = '최초거래 월';
          //  vm.header = vm.header0;
          //}
          
          vm.loadTable = false;
      })
      .catch(function (err) {
          console.log("Backtest Deatail: GET ERR", err);
      });
    },

    get_backtest_detail_next() {
      if (this.next < this.test_term.length-1) this.next += 1;
      this.yearMonth = this.test_term[this.next];
      this.get_backtest_detail(this.yearMonth);  
      this.isFirst = false;
    },

    get_backtest_detail_prv() {
      if (this.next > 0 ) this.next -= 1;
      this.yearMonth = this.test_term[this.next];
      this.get_backtest_detail(this.yearMonth);
      this.next == 0 ? this.isFirst = true : false
    },
    
    getColor (trade_type) {
      if (trade_type === "매도") return 'red'
      else if (trade_type === "유지") return 'orange'
      else return 'green'
    },

    getNumColor (num) {
      // const num = parseInt(str.replace(/,/g,""));
      if (num > 0) return 'green'
      else if (num < 0) return 'red'
      else return 'gray'
    },

    changeOrder () {
      this.test_term.reverse();        
      this.yearMonth = this.test_term[0];
      this.get_backtest_detail(this.yearMonth); 
    },
    
  }
}
</script>



<style>
.backtest-detail{
    margin-top: 10px;
}
</style>